<template>
  <div class="main-content" >
    <ISAShortPolling interval=5000 />
  <img class="logo" :src="IMGService.logoMintt" alt="" style="width: 200px"/>

  <b-jumbotron id="jbtron" bg-variant="info" text-variant="white" border-variant="dark">
    <template #header>MyISA <br/></template>

    <template #lead>
      Registering as kiosk device
    </template>

    <hr class="my-4">
    <p>MyISA Version: {{MYISAVERSION}}</p>
    <p>If this page appears for more than 2 minutes, then please reboot your device or contact mintt support</p>
    
  </b-jumbotron>

    
  </div>
</template>



<script>

import { IMGService } from '../../../services/img.service'
import { API_URL } from '@/.env'
import axios from 'axios';
import { SPService } from '@/services/sp.service.js'
import { VERSIONDATE, MYISAVERSION } from '@/version'
import ISAShortPolling from '@/components/ISA/shortpolling/ISAShortPolling'

const storageKeyU = 'ISAWEBAPPU';
const storageKeyF = 'ISAWEBAPPF';

export default {
  name: "UserGuide",
  data() {
          return {
            IMGService,
            MYISAVERSION,
            items: [{"title_EN":"Loading...","title_FR":"Chargement en cours...", "title_NL":"Loading..."}],                        
          }
        },

  components: {
    ISAShortPolling
  },
  computed: {
    
  },
  watch: {

  },
  mounted() {    
    const self = this;
    
    if(self.$attrs.lang) this.$i18n.locale = self.$attrs.lang;
    const jwt =  self.$attrs.jwt;
    const org =  self.$attrs.org;

    if(!jwt || !org)
         this.$router.push('/app/sessions/signIn')
    else
      this.initializeKiosk(jwt, org);

   
  },
  methods: {
   
    async initializeKiosk(jwt, org) {
      console.log('purging caches')
      window.localStorage.removeItem(storageKeyF);
      window.localStorage.removeItem(storageKeyU);
      try
      {
      await this.$store.dispatch('user/resetUserCache');
      await this.$store.dispatch('fleet/resetDataCache');
      } catch(err) {

      }
      window.localStorage.removeItem(storageKeyF);
      window.localStorage.removeItem(storageKeyU);

      console.log('fake authentication')

      // set current user as a fake user
      await this.$store.commit('user/SET_CURRENT_USER', {
        oid: '-12000',
        expiresAt: new Date('2122-12-01'),
        role: 11,
        username: 'fake',
        firstname: 'fake',
        lastname: 'fake',        
        jwt,
        organizationLabel: org,
        needNewPassword: false,
        apiUrl: API_URL,
      })

      const authStatus = await SPService.checkAuth(API_URL.url, this.$store.state.user.currentUser.jwt);
      if (authStatus > 0)
      {
        console.error('invalid or expired jwt token, ending it here')
         this.$router.push('/app/kiosk')
         return;
      }

      try
      {
        console.log('set current organization')
        await this.$store.dispatch('fleet/setCurrentOrganization', org);
      
      } catch(err) {
        
      }

      while(!this.$store.state.fleet.roomsetList || !this.$store.state.fleet.roomsetList.length || !this.$store.state.fleet.roomsetList.filter(p => p.organizationLabel === org  && p.label.charAt(0) !== '_').length)
      {
          console.log('wait for roomsetList to be populated');
          await new Promise(resolve => setTimeout(resolve, 1000));
          try
          {
            console.log('set fake organization')
            await this.$store.dispatch('fleet/setCurrentOrganization', 'zzzzzzzzzzzzzzzzzzzzzzz');
            await this.$store.dispatch('fleet/setCurrentOrganization', org);
          
          } catch(err) {
            
          }
      }
          

      try
      {
        console.log('create kiosk user')
        await this.$store.dispatch('user/needsKioskCreatesUser', { jwt, org });
        console.log('logout fake user')
        await this.$store.commit('user/SET_CURRENT_USER', this.$store.state.user.kioskUser, { root: true })
      
      } catch(err) {
        
      }            
      this.$router.push('/app/kiosk')
    },
    
  }
};
</script>

<style lang="scss" >
.main-content {
  margin: 100px
}
.my-flex-card {
    height: calc(100% - 15px);
    margin-bottom: 15px;
}

.TreeNavigation {
    display: inline-block;
    padding: 0;
    margin: 0;
}


.NavigationToggle__icon {
    display: inline-block;
    padding: 3px;
    border: solid #000;
        border-top-width: medium;
        border-right-width: medium;
        border-bottom-width: medium;
        border-left-width: medium;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.NavigationToggle {
    position: relative;
    top: -3px;
    padding: 5px 5px 5px 3px;
    cursor: pointer;
}

.guidecontent {
  font-size: x-large;
}

#toc_container {
    background: #f9f9f9 none repeat scroll 0 0;
    border: 1px solid #aaa;
    display: table;
    font-size: 95%;
    margin-bottom: 1em;
    padding: 20px;
    width: auto;
}

.toc_title {
    font-weight: 700;
    text-align: center;
}

#toc_container li, #toc_container ul, #toc_container ul li{
    list-style: outside none none !important;
}

#jbtron{
  
  margin-top: 50px;
  text-align: center;
}
.jumbotron {
    background-color: #88cabe !important; 
    
}

#jbtron h1 {
  font-weight: 900 !important;
  margin: 0 0 50px;  
  font-weight: 900;
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  color: #000000;
}

.lead {
    font-weight: 900 !important;
  margin: 0 0 50px;  
  font-weight: 900;
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  color: #000000;
}

</style>
